define([], () => {

  const ageGateMessageBanner = () => {
    const component = {};

    const _config = {
      closeButton: '[data-js-element=close-age-banner-button]',
      ageNotVerifiedBanner: '[data-age-not-verified]',
      ageNotProvidedBanner: '[data-age-not-provided]'
    };

    const _init = (element) => {
      component.element = element;

      document.querySelectorAll(component.config.closeButton).forEach(element => {
        element.addEventListener('click', () => {
          component.closeButtonClickListener();
        })
      })

      if(window.location.search.indexOf('ageVerified') > -1) {
        component.showAgeNotVerifiedMessageBanner();
      } else if (window.location.search.indexOf('ageProvided') > -1) {
        component.showAgeNotProvidedMessageBanner();
      }

      return component;
    };

    const _closeButtonClickListener = () => {
      component.element.remove();
    };

    const _showAgeNotVerifiedMessageBanner = () => {
      component.element.querySelector(component.config.ageNotVerifiedBanner).style.display = "flex";
    };

    const _showAgeNotProvidedMessageBanner = () => {
      component.element.querySelector(component.config.ageNotProvidedBanner).style.display = "flex";
    };

    component.init = _init;
    component.config = _config;
    component.closeButtonClickListener = _closeButtonClickListener;
    component.showAgeNotVerifiedMessageBanner = _showAgeNotVerifiedMessageBanner;
    component.showAgeNotProvidedMessageBanner = _showAgeNotProvidedMessageBanner;

    return component;
  };

  return ageGateMessageBanner;
});
